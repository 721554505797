<template>
    <div class="news">
        <div class="newslist">
            <div class="item" v-for="(item, index) in list" :key="index" @click="goPages('/Tasting/news/info?id=' + item.id +'&store_id='+store_id)">
                <div class="pic">
                    <VanImage
                            width="2rem"
                            height="2rem"
                            fit="cover"
                            lazy-load
                            :src="item.images"
                    />
                </div>
                <div class="newslistcon">
                    <div class="Ellipses1 tit">{{item.title}}</div>
                    <div class="Ellipses2 desc">{{item.content}}</div>
                    <div class="newsfooter">
                        <span>{{item.time}}</span>
                        <span class="Ellipses1 cityname">{{item.city}}</span>
                        <span class="Ellipses1 storename font-color">{{item.store_name}}</span>
                    </div>
                </div>
            </div>

            <div class="Loading" v-if="loading">
                <Loading size="24px">加载中...</Loading>
            </div>
            <div class="Loading" v-if="finished">没有更多了~</div>
        </div>
    </div>
</template>
<script>
    import Backtop from "./../components/Backtop";
    import { Toast } from "vant";
    import { Image as VanImage } from 'vant';
    import { DynamicList } from "@api/tasting";
    export default {
        name: "news",
        components: {
            Backtop, VanImage
        },
        props: {
            storeid:{
                type:Number,
                default:0
            }
        },
        data: function() {
            return {
                list: [],
                loading: false,
                finished: false,
                page: 1,
                limit: 10,
                store_id:0
            };
        },
        created() {
            this.store_id=this.$route.query.store_id==undefined||this.$route.query.store_id==''||this.$route.query.store_id==0?this.$store.state.app.storeid:this.$route.query.store_id;
            console.log(this.store_id);
        },
        mounted: function() {
            this.Loadmore();
            var that = this;
            window.addEventListener("scroll", function(event) {
                var scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
                if(document.documentElement.scrollHeight == document.documentElement.clientHeight + scrollTop ) {
                    that.Loadmore();
                }
            });
        },
        methods: {
            goPages: function(url) {
                this.$router.push({ path: url });
            },
            Loadmore: function() {
                if(this.loading) return;
                if(this.finished) return;
                this.getDynamicList();
            },
            getDynamicList: function() {
                this.loading = true;
                DynamicList({
                    page: this.page,
                    limit: this.limit,
                    store_id:this.store_id
                }).then(res => {
                    var listdata = res.data.data;
                    listdata.forEach((item)=>{
                        this.list.push(item);
                    })
                    this.loading = false;
                    this.page++;
                    if(listdata.length<5){
                        this.finished = true;
                    }
                })
                .catch(err => {
                    this.loading = false;
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
                console.log(this.$store.state.app.storeid);
            },
        }
    };
</script>
<style scoped lang="scss">
.Loading{text-align: center;padding:0.2rem;font-size:0.28rem;color:#999;}
.news{
    .newstit{
        position: relative;height: 0.8rem;margin: 0.2rem;
        img{width: auto;height: 100%;}
        a{position: absolute;right: 0;top: 0.4rem;font-size: 0.24rem;}
    }
    .newslist{
        .item{
            background: #fff;padding: 0.2rem;overflow: hidden;
            .pic{
                background: #f5f5f5;width: 2rem;height: 2rem;float: left;
            }
            .newslistcon{
                padding-left: 2.2rem;height: 2rem;position: relative;
                .tit{font-size: 0.28rem;font-weight: bold;line-height: 0.5rem;}
                .desc{font-size: 0.2rem;text-align: justify;}
                .newsfooter{
                    position: absolute;bottom: 0;right: 0;left: 2.2rem;font-size: 0.2rem;
                    span{float: left;display: inline-block;line-height: 0.3rem;}
                    .cityname{margin: 0 0.1rem;max-width: 1.4rem;}
                    .storename{float: right;max-width: 2rem;}
                }
            }
        }
    }
}
</style>